<script setup lang="ts">
const error = useError()
const i18n = useI18n()
const api = useApi()
const requestUrl = useRequestURL()
const value = ref(0)

function incrementValue() {
  value.value += 0.01

  if (value.value < 1) {
    setTimeout(() => incrementValue(), 200)
  }
}

incrementValue()

// checks

async function checkForHelpPage(
  slug: string,
  parameters: URLSearchParams,
): Promise<string | null> {
  if (
    [
      'hulp-nodig',
      'potrzebna-pomoc',
      'precisa-de-ajuda',
      'behover-hjalp',
      'brauchen-sie-hilfe',
      'need-help',
      'necesitas-ayuda',
      'tarvitsen-apua',
      'besoin-d-aide',
      'bisogno-di-aiuto',
    ].includes(slug)
  ) {
    return getRoute(`help?${parameters.toString()}`)
  }

  return null
}

async function checkForMyOutspot(
  slug: string,
  parameters: URLSearchParams,
): Promise<string | null> {
  if (
    [
      'mijn-aankopen',
      'moje-zakupy',
      'as-minhas-compras',
      'mina-kop',
      'meine-ankaufe',
      'my-purchases',
      'mis-compras',
      'omat-ostokset',
      'mes-achats',
      'i-miei-acquisti',
    ].includes(slug)
  ) {
    return getRoute(`account/my-orders?${parameters.toString()}`)
  }

  return null
}

async function checkForDoiConfirmation(
  slug: string,
  parameters: URLSearchParams,
): Promise<string | null> {
  if (
    [
      'bevestig-registratie',
      'potwierdź-rejestrację',
      'confirmar-registo',
      'bekrafta-registrering',
      'bestatigung-anmeldung',
      'confirm-registration',
      'confirmar-subscripcion',
      'vahvista-rekisteroityminen',
      'confirmation-inscription',
      'conferma-registrazione',
    ].includes(slug.split('/').shift() ?? '')
  ) {
    return getRoute(`account/confirm?token=${slug.split('/').pop() ?? ''}`)
  }

  return null
}

async function checkForUnsubscriptionConfirmation(
  slug: string,
  parameters: URLSearchParams,
): Promise<string | null> {
  if (
    [
      'abmelden',
      'unsubscribe',
      'confirmar-registo',
      'cancelar-subscripcion',
      'peruuta-tilaus',
      'se-desinscrire',
      'disdetta-newsletter',
      'uitschrijven',
      'wypisz',
      'anular-subscricao',
      'avsluta-prenumeration',
    ].includes(slug.split('/').shift() ?? '')
  ) {
    return getRoute(`account/unsubscribe?${parameters.toString()}`)
  }

  return null
}

async function checkForTestlink(
  slug: string,
  parameters: URLSearchParams,
): Promise<string | null> {
  if (slug.includes('testproduct')) {
    try {
      const deal = (await api.deals.get(parseInt(slug.split('/').pop() ?? '0')))
        .data.value

      if (deal?.slug) {
        return getRoute(`deals/test/${deal.slug}`)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return null
}

async function checkForDealPage(
  slug: string,
  parameters: URLSearchParams,
): Promise<string | null> {
  try {
    const deal = (await api.deals.getBySlug(slug)).data.value

    if (deal?.slug) {
      return getRoute(`deals/${deal.slug}?${parameters.toString()}`)
    }
  } catch (e) {
    console.error(e)
  }

  return null
}

async function redirectToOldWebsite(
  slug: string,
  parameters: URLSearchParams,
): Promise<string | null> {
  parameters.append('site_version', 'new')

  return `${i18n.t('route.fallback_domain')}${requestUrl.pathname}?${parameters.toString()}`
}

// execute all checks async and redirect if needed

if (error.value?.message?.includes('Page not found')) {
  await (async () => {
    // do nothing on the server
    if (import.meta.server) {
      return
    }

    // only handle errors once per page load
    // @ts-ignore
    globalThis.errorHandled = globalThis.errorHandled ?? false

    // @ts-ignore
    if (globalThis.errorHandled) {
      return
    }

    // @ts-ignore
    globalThis.errorHandled = true

    // get the requested slug
    const slug = requestUrl.pathname
      .toString()
      .replace('travel/', '')
      .replace('/---', '')
      .split('/')
      .slice(2)
      .join('/')

    const parameters = requestUrl.searchParams

    // checks
    const checks = [
      checkForHelpPage,
      checkForMyOutspot,
      checkForDoiConfirmation,
      checkForUnsubscriptionConfirmation,
      checkForTestlink,
      checkForDealPage,
      // if all previous checks fail, redirect to old website
      redirectToOldWebsite,
    ]

    for (const check of checks) {
      console.log('Checking for', check.name)
      const result = await check(slug, parameters)

      if (result) {
        console.info('Match for', check.name, result)
        globalThis.location.href = result
        break
      } else {
        console.error('No match for', check.name)
      }
    }
  })()
} else {
  console.log(error.value)
}

console.log('error-page')
</script>

<template>
  <ion-page>
    <os-row class="please-wait">
      <os-column>
        <span>{{ $t('label.please_wait') }}</span>
        <ion-progress-bar
          :value="value"
          :type="value >= 1 ? 'indeterminate' : 'determinate'"
          class="gutter-above"
        ></ion-progress-bar>
      </os-column>
    </os-row>
  </ion-page>
</template>

<style>
.please-wait {
  height: 100svh;
  width: 100svw;
  font-weight: bold;
}

.please-wait ion-progress-bar {
  width: 33svw;
  --background: var(--accent--transparent);
  --progress-background: var(--accent);
}
</style>
